import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Header.css';
import './Keyframes.css';
import './Responsiveness.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice, faGift, faDollarSign, faTrophy, faStopwatch, faChevronDown, faPhone, faBars, faWallet } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isLoggedIn] = useState(localStorage.getItem("isAuthenticated") === "true");
  const [balance] = useState(0);
  const [showGamesDropdown, setShowGamesDropdown] = useState(false);
  const [showRewardsDropdown, setShowRewardsDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => setIsMobileMenuOpen(prev => !prev);

  // Toggle modal
  const toggleModal = () => setIsModalOpen(prev => !prev);

  // Game tiles data
  const gameTiles = [
    { src: "/assets/limbo.webp", alt: "Limbo", to: "/limbo", newBadge: true },
    { src: "/assets/coinflip.webp", alt: "Coinflip", to: "/coinflip", newBadge: true },
    { src: "/assets/mines.webp", alt: "Mines", disabled: true },
    { src: "/assets/crash.webp", alt: "Crash", disabled: true },
    { src: "/assets/roulette.webp", alt: "Roulette", disabled: true },
    { src: "/assets/blackjack.webp", alt: "Blackjack", disabled: true },
    { src: "/assets/towers.webp", alt: "Towers", disabled: true },
    { src: "/assets/unboxing.webp", alt: "Unboxing", disabled: true },
    { src: "/assets/dice.webp", alt: "Dice", disabled: true },
    { src: "/assets/keno.webp", alt: "Keno", disabled: true },
    { src: "/assets/plinko.webp", alt: "Plinko", disabled: true },
    { src: "/assets/cross_the_road.webp", alt: "Cross The Road", disabled: true }
  ];

// Render game tiles
const renderGameTiles = () => {
  return gameTiles.map((tile, index) => (
    <div key={index} className={`header-tile-container ${tile.disabled ? 'header-disabled' : ''}`}>
      {tile.newBadge && <span className="header-new-badge">New!</span>}
      <Link to={tile.disabled ? '#' : tile.to} className={`header-tile ${tile.disabled ? 'header-disabled' : ''}`}>
        <img src={tile.src} alt={tile.alt} />
      </Link>
      {tile.disabled && <span className="header-coming-soon-badge">Coming soon</span>}
    </div>
  ));
};


  return (
    <div className="header-container">
      <header className="header-header">
        <div className="header-logo">
          <Link to="/">
            <img src="assets/logo.webp" alt="Casino Logo" />
          </Link>
        </div>
        <button className="header-mobile-menu-icon" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <nav className={`header-nav-menu ${isMobileMenuOpen ? 'header-open' : ''}`}>
          {/* Games Dropdown */}
          <div
            className="header-nav-item"
            onMouseEnter={() => setShowGamesDropdown(true)}
            onMouseLeave={() => setShowGamesDropdown(false)}
          >
            <FontAwesomeIcon icon={faDice} />
            <span>Games</span>
            <FontAwesomeIcon icon={faChevronDown} className="header-dropdown-indicator" />
            {showGamesDropdown && (
              <div className="header-dropdown">
                {renderGameTiles()}
              </div>
            )}
          </div>

          {/* Rewards Dropdown */}
          <div
            className="header-nav-item"
            onMouseEnter={() => setShowRewardsDropdown(true)}
            onMouseLeave={() => setShowRewardsDropdown(false)}
          >
            <FontAwesomeIcon icon={faGift} />
            <span>Rewards</span>
            <FontAwesomeIcon icon={faChevronDown} className="header-dropdown-indicator" />
            {showRewardsDropdown && (
              <div className="header-dropdown2">
                <div className="header-tile-container">
                  <span className="header-coming-soon-badge">Coming Soon</span>
                  <div className="header-tile header-disabled">
                    <img src="/assets/rakeback-bddad735.webp" alt="Rakeback" />
                  </div>
                </div>
                <div className="header-tile-container">
                  <span className="header-coming-soon-badge">Coming Soon</span>
                  <div className="header-tile header-disabled">
                    <img src="/assets/promo-f3007513.webp" alt="Promo Codes" />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Other Navigation Links */}
          <Link to="/affiliates" className="header-nav-item">
            <FontAwesomeIcon icon={faDollarSign} /> Affiliates
          </Link>
          <Link to="/leaderboard" className="header-nav-item">
            <FontAwesomeIcon icon={faTrophy} /> Leaderboard
          </Link>
          <Link to="/race" className="header-nav-item">
            <FontAwesomeIcon icon={faStopwatch} /> Race
          </Link>
        </nav>

        <div className="header-cta">
          {isLoggedIn ? (
            <div className="header-balance-container">
              <div className="header-balance">
                <img src="assets/currency.webp" alt="Currency Icon" className="currency-icon" />
                <span>{balance.toFixed(2)}</span>
              </div>
              <button className="header-wallet-button" onClick={toggleModal}>
                <FontAwesomeIcon icon={faWallet} />
              </button>
            </div>
          ) : (
            <>
              <Link to="/login" className="header-login-btn">Login</Link>
              <Link to="/register" className="header-register-btn">Register</Link>
            </>
          )}
        </div>

        {isModalOpen && (
          <div className="header-modal-overlay" onClick={toggleModal}>
            <div className="header-modal" onClick={(e) => e.stopPropagation()}>
              <h2>Deposit & Withdrawal</h2>
              <p>For deposits and withdrawals, please contact us. Transactions are currently processed manually.</p>
              <div className="modal-icon-buttons">
                <a href="https://discord.com/users/256620135320649728" target="_blank" rel="noopener noreferrer" className="icon-button">
                  <FontAwesomeIcon icon={faPhone} /> Discord
                </a>
              </div>
              <button className="close-button" onClick={toggleModal}>Close</button>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
