import React from 'react';
import './ErrorPages.css';

const Forbidden = ({ errorCode, imagePath, errorMessage }) => {
  const openCrispChat = (e) => {
    e.preventDefault();
    if (typeof window.$crisp !== "undefined") {
      if (window.$crisp.is("chat:opened")) {
        window.$crisp.push(["do", "chat:close"]);
      } else {
        window.$crisp.push(["do", "chat:open"]);
      }
    } else {
      const script = document.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      script.onload = () => {
        window.$crisp.push(["do", "chat:open"]);
      };
      document.head.appendChild(script);
    }
  };

  return (
    <div className="error-container">
      <img src={imagePath} alt="Error" className="error-image" />
      <h1>{errorCode}</h1>
      <p>{errorMessage}</p>
      <p className="error-support-message">If you need further assistance, please contact our support team.</p>
      <button className="error-support-button" onClick={openCrispChat}>Contact Support</button>
      <div className="error-footer-image">
        <img src="/assets/Footer_Pattern.png" alt="Grid" />
      </div>
    </div>
  );
};

const Error403 = () => {
  return <Forbidden errorCode="403 Forbidden" imagePath="/assets/cj.webp" errorMessage="Sorry, you are not authorized to access this page." />;
};

export default Error403;
